import React from 'react';

import styles from './ThankYouHeader.module.css';

import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';


const ThankYouHeader = () => {
  return (
    <div className={styles.thankYouHeader}>
      <Container type="mobile">
        <Title className={`${styles.thankYouHeader__title} mb-3`}>Thank you!</Title>
        <Text className={`${styles.thankYouHeader__subtitle} text-center`}>We're processing your order, and you'll receive a confirmation email in a few minutes. Please send us a message through our website if you have any questions. 🙂</Text>
        <Image name="man_with_pc" className={styles.thankYouHeader__image} />
      </Container>
    </div>
  )
};

export default ThankYouHeader;