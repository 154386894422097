import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import ThankYouHeader from '../components/slides/thank-you/ThankYouHeader';

const ThankYou = () => {
  return (
    <Layout>
      <SEO
        title="Thank you | Deadline Funnel"
        description="Thank you for your order!"
         meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <ThankYouHeader />
    </Layout>
  )
};

export default ThankYou;
